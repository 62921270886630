"use client";

import VerticalPhoneNumber from "@/app/components/verticalPhoneNumbers";
import { Button } from "react-bootstrap";
import styles from "./notFound.module.scss";

export default function NotFound() {
  return (
    <div id={styles["server-in-not-found-state"]}>
      <section>
        <div className={styles["oops-image"]}>
          <img
            alt=""
            width="480.69px"
            height="250px"
            src={`/media/dental/common/backgrounds/Oops-Image.png`}
            srcSet={`/media/dental/common/backgrounds/Oops-Image.png,
                                /media/dental/common/backgrounds/Oops-Image@2x.png 2x`}
          />
        </div>
        <h1>We&#39;re sorry, we can&#39;t find the page you&#39;re looking for.</h1>
        <p>
          It&#39;s possible this page has moved to a different location. If the problem persists or you can&#39;t find
          what you&#39;re looking for please contact Net32 at{" "}
          <strong>
            <VerticalPhoneNumber format="(###) ###-####" type="oneEightHundred" className={styles["phoneNumber"]} />
          </strong>{" "}
          or email <a href={`mailto:support@net32.com`}>support@net32.com</a>.
        </p>
        <Button variant="secondary" href="/" className="take-me-home btn-pillbox" size="lg">
          Take me home
        </Button>
      </section>
    </div>
  );
}
